import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout 
    title = { 'Page not found' } 
  >
    <div
      style = {{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <h3>Page not found</h3>
      <h5>The page you are looking for could not be found click <Link to = { '/' } >Here</Link> to return to home page.</h5>
    </div>
  </Layout>
)

export default NotFoundPage
